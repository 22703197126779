import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"

import Layout from "../../components/layout"
import Menu from "../../components/menu"
import Footer from "../../components/footer"
import ContractorCard from "../../components/contractorCard"
import Benefits from "../../components/benefits"
import FAQ from "../../components/faq"
import { getContractor } from "../../graphql/queries.js"
import { API, Auth } from "aws-amplify"

const ContractorSitePage = props => {
  const [data, setData] = useState(undefined)
  const [id, setID] = useState(props["*"] || undefined)

  useEffect(() => {
    console.log("began fetch.....")
    console.log(props)

    console.log(id)
    async function fetchData() {
      let isDevUser = false

      try {
        if (id == null) return Promise.resolve(navigate("/404/"))

        console.log("onMount check auth user: ", Auth)
        const anyUser = Auth?.user

        if (anyUser == null) {
          isDevUser = true
          // TODO: Switch to an Key based api call instead of user based.
          await Auth.signIn("apkanwar@gmail.com", "password")

        }

        // We already make this reqeust if we are coming from dashboard - maybe something to work on
        const result = await API.graphql({
          query: getContractor,
          variables: {
            id: id,
          },
          authMode: "AWS_IAM",
        })
        // This flag is too fragile update later
        if (isDevUser) {
          await Auth.signOut()
          isDevUser = false
        }

        console.log("results: ", result)
        console.log("getContractor: ", result?.data?.getContractor)

        const d = result?.data?.getContractor
        if (d == null) {
          if (isDevUser) await Auth.signOut()
          navigate("/404/")
        }

        setData(d)
      } catch (e) {
        console.log("Error: ", e)
        if (isDevUser) await Auth.signOut()
        navigate("/404/")
      }
    }
    fetchData()
  }, [id])

  return (
    <Layout type={1}>
      <Menu
        type={2}
        btype={3}
        cDetails={[id, data?.name, data?.logoFileName]}
      />
      <ContractorCard data={data} />
      <Benefits type={2} />
      <FAQ type={2} />
      <Footer type={2} />
    </Layout>
  )
}

export default ContractorSitePage
