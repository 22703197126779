import React from "react"
import styles from "./contractorCard.module.css"
import pic from "../images/contractorSite/image1.png"
import { Link } from "gatsby"

const Card1 = ({ data }) => {
  return (
    <div id="home" className={styles.container}>
      <div className={styles.row}>
        <div className={styles.columnb}>
          <div>
            <div className={styles.head}>
              Financing your{" "}
              <span className={styles.highlight}>Home Improvement</span> Needs
            </div>
            <div className={styles.main}>
              Get <span className={styles.highlight}>competitive</span> rates
              within minutes while not affecting your{" "}
              <span className={styles.highlight}>credit score</span>.
            </div>
            <div className={styles.main}>
              Whether you are looking to do a{" "}
              <span className={styles.highlight}>complete home remodel</span>,
              or just finance some new furniture,{" "}
              <span className={styles.highlight}>FinSimpl</span> has you covered
            </div>
            <Link to="/clientFormPage" state={{ info: data }}>
              <div className={styles.button}>
                <div className={styles.button_text}>Get Started</div>
              </div>
            </Link>
          </div>
        </div>
        <div className={styles.columna}>
          <img
            src={pic}
            className={styles.imgs}
            height="626"
            width="900"
            alt="Painters Again"
          />
        </div>
      </div>
    </div>
  )
}

export default Card1
